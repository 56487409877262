<template>
  <RichTextVueParser v-bind="props" />
</template>

<script lang="ts" setup>
import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'

import RichTextVueParser from './RichTextVueParser.vue'

const props = withDefaults(defineProps<RichTextProps>(), {
  lightText: false,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])
</script>
